require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es7.symbol.async-iterator");

require("core-js/modules/es6.symbol");

require("core-js/modules/web.dom.iterable");

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
} // This file must be in CommonJS format since it is imported in gatsby-node.js


const {
  linkResolverArrayPathFor
} = require('@walltowall/helpers');

const {
  get,
  has
} = require('tiny-compose-fns/noFp'); // Returns true if linkResolver is called within a Rich Text context. This
// differs from calling it in a Gatsby context where we use the recursive
// parent query.
//
// We have access to only one level of nesting in Rich Text and requires
// manually assigning anything deeper.


const isCalledInRichText = doc => !has('data.parent.data.parent.uid', doc);

exports.linkResolver = () => doc => {
  let path = linkResolverArrayPathFor(doc);
  const normal = [null].concat(_toConsumableArray(path), [null]).join('/');

  switch (get('data.parent.uid', doc)) {
    case 'residencies':
    case 'exhibitions':
    case 'events':
      if (isCalledInRichText(doc)) return [null, 'visit'].concat(_toConsumableArray(path), [null]).join('/');else normal;

    default:
      return normal;
  }
};